export const initialState = {
  color: "#ffb400",
}

const reducer = (state = initialState, action) => {
    
    switch (action.type) {
        case "UPDATE_COLOR":
            return { ...state, color: action.payload.color }
        default:
            return state;
    }

}

export default reducer;
