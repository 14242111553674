import React, { createContext, useContext, useReducer } from "react"
import reducer, { initialState } from "../contextProvider/reducer"
export const StateContext = createContext()

const StateProvider = ({ children }) => {
  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StateContext.Provider>
  )
}

export default StateProvider;

export const useStateValue = () => useContext(StateContext)
